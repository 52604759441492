import { KlevuConfig, KlevuKMCSettings } from '@klevu/core';
import { useExternalScript } from '@oriola-corporation/ui-components';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useCookieConsent } from '../cookiebot';

interface KlevuContextType {
  getRedirectUrl: (term: string) => string;
}

const KlevuContext = createContext<KlevuContextType>({
  getRedirectUrl: () => '',
});

export const useKlevu = () => useContext(KlevuContext);

interface Redirects {
  keywords: string[];
  url: string;
}

interface Props {
  apiKey: string;
  baseUrl: string;
}

declare global {
  interface Window {
    klevu_webstorePopularTerms?: string[];
    klevu_uc_userOptions?: unknown;
    klevu_keywordUrlMap: Redirects[];
  }
}

export const KlevuSearchProvider: React.FC<React.PropsWithChildren<Props>> = ({
  apiKey,
  baseUrl,
  children,
}) => {
  const { hasAcceptedNecessaryCookies } = useCookieConsent();
  const [redirects, setRedirects] = useState<Redirects[]>([]);

  if (hasAcceptedNecessaryCookies) {
    KlevuConfig.init({
      url: `${baseUrl}/cs/v2/search`,
      apiKey,
    });

    KlevuKMCSettings().catch(() => {
      console.log('Failed to fetch Klevu settings');
    });
  }

  const redirectsReady = useExternalScript(
    `https://js.klevu.com/klevu-js-v1/klevu-js-api/${apiKey}-maps.js`,
    { async: true, enabled: hasAcceptedNecessaryCookies }
  );

  useEffect(() => {
    if (redirectsReady) {
      const redirects = window.klevu_keywordUrlMap?.map((redirect) => ({
        ...redirect,
        keywords: redirect.keywords.map((keyword) => keyword.toLowerCase()),
      }));
      setRedirects(redirects);
    }
  }, [redirectsReady]);

  const contextValue: KlevuContextType = useMemo(
    () => ({
      getRedirectUrl: (searchTerm) => {
        const matchingRedirect = redirects.find((redirect) =>
          redirect.keywords.includes(searchTerm.toLowerCase())
        );

        if (matchingRedirect) {
          return matchingRedirect.url;
        }

        return '';
      },
    }),
    [redirects]
  );

  return <KlevuContext.Provider value={contextValue}>{children}</KlevuContext.Provider>;
};
